import styled from "styled-components";

const LeftMenuContainer = styled.div`
  width: 5vw;
  height: 100%;
  background-color: #07260D;
  height:100%;
  position: fixed;
  left: 0;
  display:flex;
  flex-direction:column;
  font-size: 0.625vw;
  color:#ffffff;
  font-family: 'DIN-Pro';
  
`;
export { LeftMenuContainer };
