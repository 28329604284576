
import React from 'react'
import teamJHZ from '../media/creator/ProfilePic Creator - Team JHZ.webp'
import pretattica from '../media/creator/ProfilePic Creator - Pretattica.webp'
import fantaballa from '../media/creator/ProfilePic Creator - Fantaballa.webp'
import tonysalumiere from '../media/creator/ProfilePic Creator - TonyIlSalumiere.webp'
import michelapilatti from '../media/creator/ProfilePic Creator - michelapilatti.webp'
import mattiadeitaluni from '../media/creator/ProfilePic Creator - mattia_deitaluni.webp'
import giankoo from '../media/creator/ProfilePic Creator - giankooo.webp'

import andreSawi from '../media/creator/ProfilePic Creator - andresawi.webp'
import Ciuffo from '../media/creator/ProfilePic Creator - ciuffogamer.webp'
import sankace from '../media/creator/ProfilePic Creator - sank_ace.webp'
import Claudia from '../media/creator/ProfilePic Creator - claudietta_fn.webp'
import Rosyberty from '../media/creator/ProfilePic Creator - rosyberty.webp'
import Nonno from '../media/creator/ProfilePic Creator - nonnoerotiko.webp'
import GianmarcoFede from '../media/creator/ProfilePic Creator - giamm4fed3.webp'
import Ludo from '../media/creator/ProfilePic Creator - ludo.tet.webp'
import theking from '../media/creator/ProfilePic Creator - thekinglisander.webp'
import ionumero from '../media/creator/ProfilePic Creator - io.numero.perfett.webp'
import ilcotto from '../media/creator/ProfilePic Creator - ilcotto_cdk.webp'
import lafamiglianerd from '../media/creator/ProfilePic Creator - lafamiglianerd.webp'
import Carodecastello from '../media/creator/ProfilePic Creator - carodecastello-1.webp'
import PartyTuning from '../media/creator/ProfilePic Creator - partytuning.webp'
import Veesky from '../media/creator/ProfilePic Creator - veeskyyontiktok.webp'
import fenomeno from '../media/creator/ProfilePic Creator - ilfenomenopeter.webp'
import giogio from '../media/creator/ProfilePic Creator - giogio0991.webp'
import enzoofficial from '../media/creator/ProfilePic Creator - enzo_officialpage.webp'
import veronicasammarco from '../media/creator/ProfilePic Creator - veronicasammarco.webp'
import crudest from '../media/creator/ProfilePic Creator - crudest_tv.webp'
import gambler_pro from '../media/creator/ProfilePic Creator - gambler__pro.webp'
import keymathreal from '../media/creator/ProfilePic Creator - keymathereal (DA CAMBIARE).webp'
import martybusoni from '../media/creator/ProfilePic Creator - marty_busoni (DA CAMBIARE).webp'
import saddy_kitty from '../media/creator/ProfilePic Creator - saddy_.kitty (DA CAMBIARE).webp'
import mayatiberi from '../media/creator/ProfilePic Creator - mayatiberi.webp'
import bby_vlaa from '../media/creator/ProfilePic Creator - bby.vlaa_.webp'
import nicolebusetto from '../media/creator/ProfilePic Creator - nicole.busetto.webp'
import albericodip from '../media/creator/ProfilePic Creator - alberico_dip.webp'
import mariacataldo from '../media/creator/ProfilePic Creator - mariacataldo955.webp'
import antoni from '../media/creator/ProfilePic Creator - antoniacarannante.webp'
import jeyjessy from '../media/creator/ProfilePic Creator - jeyjessy23.webp'
import monic from '../media/creator/ProfilePic Creator - monic5461.webp'
import melaniaandreasopp from '../media/creator/ProfilePic Creator - melaniaandreasopp.webp'
import elisabettagenoves8 from '../media/creator/ProfilePic Creator - elisabettagenoves8.webp'
import cicciobari17_ttv from '../media/creator/ProfilePic Creator - cicciobari17_ttv.webp'
import assiamarongiu from '../media/creator/ProfilePic Creator - assiamarongiu.webp'
import idaa_h from '../media/creator/ProfilePic Creator - idaa_.h.webp'
import giorgiainitaliano from '../media/creator/ProfilePic Creator - giorgiainitaliano.webp'
import neagudananicoleta from '../media/creator/ProfilePic Creator - neagudananicoleta.webp'
import ale_occhiblu_12 from '../media/creator/ProfilePic Creator - ale.occhiblu.12.webp'
import pakito0994 from '../media/creator/ProfilePic Creator - pakyto0994.webp'
import hatim047_jpg from '../media/creator/ProfilePic Creator - hatim047_jpg.webp'
export const creators = [
  { id: 1, nome: 'TonyilSalumiere', src: tonysalumiere, tiktok: 'https://www.tiktok.com/@tonyilsalumiere', categorie: ['Gaming', 'Sport'] },
  { id: 2, nome: 'Mattia_deiTaluni', src: mattiadeitaluni, tiktok: 'https://www.tiktok.com/@mattia_deitaluni', categorie: ['Musica e Canto', 'Vlog personali'] },
  { id: 3, nome: 'Giankooo', src: giankoo, tiktok: 'https://www.tiktok.com/@giankooo?_t=8mEflLpqGFE&_r=1', categorie: ['Vlog personali'] },
  { id: 4, nome: 'Michelapilatti', src: michelapilatti, tiktok: 'https://www.tiktok.com/@michelapilatti', categorie: ['Vlog personali'] },
  { id: 5, nome: 'Sank_Ace', src: sankace, tiktok: 'https://www.tiktok.com/@sank_ace', categorie: ['Gaming'] },
  { id: 6, nome: 'RosyBerty', src: Rosyberty, tiktok: 'https://www.tiktok.com/@rosyberty', categorie: ['Vlog personali', 'Animali'] },
  { id: 7, nome: 'PreTattica_official', src: pretattica, tiktok: 'https://www.tiktok.com/@pretattica_official', categorie: ['Sport'] },
  { id: 8, nome: 'AndreSawi', src: andreSawi, tiktok: 'https://www.tiktok.com/@andresawi', categorie: ['Gaming'] },
  { id: 9, nome: 'Claudia', src: Claudia, tiktok: 'https://www.tiktok.com/@claudietta_fn?_t=8mOJzroqELp&_r=1', categorie: ['Gaming', 'Vlog personali'] },
  { id: 10, nome: 'Carodecastello_TV', src: Carodecastello, tiktok: 'https://www.tiktok.com/@carodecastello', categorie: ['Gaming'] },
  { id: 11, nome: 'Ciuffo Gamer', src: Ciuffo, tiktok: 'https://www.tiktok.com/@ciuffogamer?lang=it-IT', categorie: ['Gaming'] },
  { id: 12, nome: 'La famiglia Nerd', src: lafamiglianerd, tiktok: 'https://www.tiktok.com/@lafamiglianerd', categorie: ['Gaming'] },
  { id: 13, nome: 'NonnoErotiko', src: Nonno, tiktok: 'https://www.tiktok.com/@nonnoerotiko?lang=it-IT', categorie: ['Balli e Coreografie', 'Vlog personali'] },
  { id: 14, nome: 'im_jamm1', src: GianmarcoFede, tiktok: 'https://www.tiktok.com/@giamm4fed3', categorie: ['Gaming'] },
  { id: 15, nome: 'PartyTuning', src: PartyTuning, tiktok: 'https://www.tiktok.com/@partytuning', categorie: ['Gaming'] },
  { id: 16, nome: 'IlCotto_cdk', src: ilcotto, tiktok: 'https://www.tiktok.com/@ilcotto_cdk', categorie: ['Vlog personali', 'Gaming'] },
  { id: 17, nome: 'TheKingLisander', src: theking, tiktok: 'https://www.tiktok.com/@thekinglisander', categorie: ['Gaming'] },
  { id: 18, nome: 'Io.Numero.Perfett', src: ionumero, tiktok: 'https://www.tiktok.com/@io.numero.perfett', categorie: ['Vlog personali'] },
  { id: 19, nome: 'VeesKyyOnTiKToK', src: Veesky, tiktok: 'https://www.tiktok.com/@veeskyyontiktok', categorie: ['Gaming'] },
  { id: 20, nome: 'Enzo_OfficialPage', src:enzoofficial , tiktok: 'https://www.tiktok.com/@enzo_officialpage?_t=8mv4bNLQktt&_r=1', categorie: ['Vlog personali'] },
  { id: 21, nome: 'Veronica Sammarco', src:veronicasammarco , tiktok: 'https://www.tiktok.com/@veronicasammarco', categorie: ['Vlog personali'] },
  { id: 22, nome: 'GioGio0991', src:giogio , tiktok: 'https://www.tiktok.com/@giogio0991', categorie: ['Vlog personali'] },
  { id: 23, nome: 'Crudest_TV', src: crudest, tiktok: 'https://www.tiktok.com/@crudest_tv', categorie: ['Intrattenimento', 'Vlog personali'] },
  { id: 24, nome: 'Nicole Busetto', src: nicolebusetto, tiktok: 'https://www.tiktok.com/@nicole.busetto', categorie: ['Vlog personali'] },
  { id: 25, nome: 'Alberico Dip', src:albericodip , tiktok: 'https://www.tiktok.com/@alberico_dip', categorie: ['Vlog personali'] },
  { id: 26, nome: 'TeamJHZ', src: teamJHZ, tiktok: 'https://www.tiktok.com/@teamjhz?lang=it-IT', categorie: ['Vlog personali'] },
  { id: 27, nome: 'Fantaballa', src: fantaballa, tiktok: 'https://www.tiktok.com/@fantaballa?lang=it-IT', categorie: ['Gaming', 'Sport'] },
  { id: 28, nome: 'Maria Cataldo', src:mariacataldo , tiktok: 'https://www.tiktok.com/@mariacataldo955?_t=8nZjMKwKizh&_r=1', categorie: ['Vlog personali'] },
  { id: 29, nome: 'Antonia Carannante', src: antoni, tiktok: 'https://www.tiktok.com/@antoniacarannante', categorie: ['Vlog personali'] },
  { id: 30, nome: 'Jey Jessy', src: jeyjessy, tiktok: 'https://www.tiktok.com/@jeyjessy23?lang=it-IT', categorie: ['Vlog personali'] },
  { id: 31, nome: 'Monic5461', src: monic, tiktok: 'https://www.tiktok.com/@monic5461', categorie: ['Vlog personali'] },
  { id: 32, nome: 'MelaniaAndreasOpp', src: melaniaandreasopp, tiktok: 'https://www.tiktok.com/@melaniaandreasopp?lang=it-IT', categorie: ['Vlog personali'] },
  { id: 33, nome: 'Elisabetta Genoves', src: elisabettagenoves8, tiktok: 'https://www.tiktok.com/@elisabettagenoves8?_t=8o6B4JNm2CW&_r=1', categorie: ['Vlog personali'] },
  { id: 34, nome: 'CiccioBari17_ttv', src: cicciobari17_ttv, tiktok: 'https://www.tiktok.com/@cicciobari17_ttv', categorie: ['Gaming'] },
  { id: 35, nome: 'Assia Marongiu', src: assiamarongiu, tiktok: 'https://www.tiktok.com/@assiamarongiu', categorie: ['Vlog personali'] },
  { id: 36, nome: 'Ida_H', src: idaa_h, tiktok: 'https://www.tiktok.com/@idaa_.h', categorie: ['Vlog personali'] },
  { id: 37, nome: 'Giorgia In Italiano', src: giorgiainitaliano, tiktok: 'https://www.tiktok.com/@giorgiainitaliano', categorie: ['Altro'] },
  { id: 38, nome: 'Neagu Dana Nicoleta', src: neagudananicoleta, tiktok: 'https://www.tiktok.com/@neagudananicoleta', categorie: ['Vlog personali'] },
  { id: 39, nome: 'Ale Occhiblu', src: ale_occhiblu_12, tiktok: 'https://www.tiktok.com/@ale.occhiblu.12', categorie: ['Vlog personali'] },
  { id: 40, nome: 'Pakyto0994', src: pakito0994, tiktok: 'https://www.tiktok.com/@pakyto0994', categorie: ['Gaming'] },
  { id: 41, nome: 'Hatim047_jpg', src: hatim047_jpg, tiktok: 'https://www.tiktok.com/@hatim047_jpg', categorie: ['Altro'] },
  { id: 43, nome: 'BbyVlaa', src: bby_vlaa, tiktok: 'https://www.tiktok.com/@bby.vlaa_', categorie: ['Vlog personali'] },
  { id: 44, nome: 'Maya Tiberi', src: mayatiberi, tiktok: 'https://www.tiktok.com/@mayatiberi', categorie: ['Vlog personali'] },
  { id: 45, nome: 'Saddy Kitty', src: saddy_kitty, tiktok: 'https://www.tiktok.com/@saddy_.kitty', categorie: ['Vlog personali'] },
  { id: 46, nome: 'Marty Busoni', src: martybusoni, tiktok: 'https://www.tiktok.com/@marty_busoni', categorie: ['Beauty e Makeup', 'Vlog personali'] },
  { id: 47, nome: 'KeymaTheReal', src: keymathreal, tiktok: 'https://www.tiktok.com/@keymathereal', categorie: ['Musica e Canto'] },
  { id: 48, nome: 'Gambler_Pro', src: gambler_pro, tiktok: 'https://www.tiktok.com/@gambler__pro', categorie: ['Gaming'] }
]