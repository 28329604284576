import { styled } from '@material-ui/styles'
import React from 'react'


const TeamInfo = () => {
    return (
        <div>TeamInfo</div>
    )
}


export default TeamInfo