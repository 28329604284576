import styled from 'styled-components'


const ButtonVerify = styled.button`
background: #2DC653;
width:275px;
height:80px;
border:none;
font-weight:700;
font-size:48px;
line-height:56.25px;
text-align:center;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
align-content:center;
color:white;
cursor: pointer;
`



const BloccoTesto = styled.div`
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
text-align: center;
width: 215px;
height: 38px;
top: 465px;
left: 613px;
gap: 0px;
opacity: 0px;


`

const SpanTesto = styled.span`
font-family: Roboto;
font-size: 16px;
font-weight: 800;
line-height: 18.75px;
text-align: center;
`

export { ButtonVerify, BloccoTesto ,SpanTesto}